import React from 'react'
import './CloudSecOpsHero.scss'

export class CloudSecOpsHero extends React.Component {
  render() {
    return (
      <div
        className="cloudsecops-page-hero"
        style={{ backgroundImage: 'url(/cloudsecopsbg.jpg)' }}
      >
        <div className="overlay" />
        <div className="inner-wrap">
          <div className="text">
            <h1>DevSecOps</h1>
            <h2>Accelerate application delivery, scalability, and security.</h2>
          </div>
        </div>
      </div>
    )
  }
}

export default CloudSecOpsHero
