import React from 'react'
import './CloudSecOpsContent.scss'
import CallToActionBanner from '../../CallToActionBanner/CallToActionBanner'
import * as Fa from 'react-icons/fa'
import { Link } from '@reach/router'

export class CloudSecOpsContent extends React.Component {
  render() {
    return (
      <div className="cloudsecops-content">
        <div className="split light subheader-block">
          <div className="text-wrap">
            Whether you're looking to migrate to the cloud, build DevSecOps
            capabilities, or stay compliant - we can help.
          </div>
        </div>
        <section className="padding-large">
          <div className="row">
            <div className="col-md-6">
              <h3>Cloud Architecture</h3>
              <div className="service-row">
                <div className="text-wrap">
                  <div className="title">
                    Containerization and Orchestration
                  </div>
                  <p>
                    Our team is experienced with containerizing workloads, and
                    using higher-level orchestrators to bake in scalability,
                    monitoring, and fault tolerance.
                  </p>
                  <div className="key-tools">
                    <span>Key Tech & Partners:</span> Docker, Kubernetes, Amazon
                    ECS
                  </div>
                </div>
              </div>
              <div className="service-row">
                <div className="text-wrap">
                  <div className="title">Serverless</div>
                  <p>
                    We're heavy users of serverless and optimization around
                    cloud-native serverless workloads.
                  </p>
                  <div className="key-tools">
                    <span>Key Tech & Partners:</span> AWS Lambda, Serverless
                    Framework
                  </div>
                </div>
              </div>
              <div className="service-row">
                <div className="text-wrap">
                  <div className="title">Hybrid or Multi Cloud</div>
                  <p>
                    Build on your existing on-premise infrastructure while
                    harnessing the flexibility of public cloud offerings.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <img src="/cloudcraft_native.png" />
            </div>
          </div>
        </section>
        <div className="split pattern">
          <h1>Migration Paths</h1>
          <div className="row migration-paths">
            <div className="col-sm-4">
              <div className="path">
                <div className="title">Lift-and-shift</div>
                <div className="icon-wrap">
                  <Fa.FaRecycle />
                </div>
                <div className="description">
                  In this method, we keep as much of the original application
                  intact as possible while incrementally moving workloads to the
                  cloud.
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="path">
                <div className="title">Replatform</div>
                <div className="icon-wrap">
                  <Fa.FaArrowAltCircleUp />
                </div>
                <div className="description">
                  In this path, services are swapped with their cloud native,
                  scalable equivalents - for instance, switching a MySQL server
                  for AWS Aurora.
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="path">
                <div className="title">Refactor</div>
                <div className="icon-wrap">
                  <Fa.FaCogs />
                </div>
                <div className="description">
                  Rewrite or re-architect portions of the application to take
                  advantage of the best that public cloud providers can offer.
                </div>
              </div>
            </div>
          </div>
          <div className="button-wrap">
            <Link to="/quote">
              <button className="btn-raised-light-blue-inverse big">
                Get a quote
              </button>
            </Link>
          </div>
        </div>
        <div className="split light no-padding no-overflow">
          <section className="padding-large">
            <div className="row">
              <div className="col-md-6 col-md-push-6">
                <h3>DevOps</h3>
                <div className="service-row">
                  <div className="text-wrap">
                    <div className="title">Continuous Integration</div>
                    <p>
                      Builds-as-a-service. Increase sprint velocity and
                      developer productivity with automated build pipelines on
                      every code change.
                    </p>
                    <div className="key-tools">
                      <span>Key Tech & Partners:</span> Jenkins, AWS CodeBuild,
                      CircleCI
                    </div>
                  </div>
                </div>
                <div className="service-row">
                  <div className="text-wrap">
                    <div className="title">Continuous Delivery</div>
                    <p>
                      Automatic or human-signed deploys to any application or
                      environment. Drastically reduce your Time To Release.
                    </p>
                    <div className="key-tools">
                      <span>Key Tech & Partners:</span> AWS CodeDeploy,
                      CircleCI, Jenkins
                    </div>
                  </div>
                </div>
                <div className="service-row">
                  <div className="text-wrap">
                    <div className="title">Test Automation</div>
                    <p>
                      Reduce risk by running unit and integration tests
                      performantly on every commit.
                    </p>
                    <div className="key-tools">
                      <span>Key Tech & Partners:</span> CircleCI, GoCD, TravisCI
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-md-pull-6">
                <div className="alt-solar">
                  <div className="line" />
                  <div className="planet1 planet-alt">
                    <Fa.FaCube />
                  </div>
                  <div className="planet2 planet-alt">
                    <Fa.FaCode />
                  </div>
                  <div className="planet3 planet-alt">
                    <Fa.FaCodeBranch />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="solar-wrap">
            <div className="ring-saturn" />

            <div className="saturn">
              <div className="planet">
                <Fa.FaCube />
              </div>
            </div>

            <div className="ring-jupiter" />

            <div className="jupiter2">
              <div className="planet">
                <Fa.FaCode />
              </div>
            </div>

            <div className="ring-pluto" />

            <div className="pluto">
              <div className="planet">
                <Fa.FaCodeBranch />
              </div>
            </div>
          </div>
        </div>
        <section className="padding-large">
          <div className="row">
            <div className="col-md-6">
              <h3>Security, Compliance & Monitoring</h3>
              <div className="service-row">
                <div className="text-wrap">
                  <div className="title">Secure Cloud Architecture</div>
                  <p>
                    Architect your cloud deployment for end-to-end security,
                    OWASP vulnerabilities, and cloud-specific attack vectors
                  </p>
                  <div className="key-tools">
                    <span>Key Tech & Partners:</span> Twistlock, Tigera, AWS Security Products
                  </div>
                </div>
              </div>
              <div className="service-row">
                <div className="text-wrap">
                  <div className="title">Continuous Compliance</div>
                  <p>
                    Monitored, self-healing deployments with compliance guardrails baked in.
                  </p>
                  <div className="key-tools">
                    <span>Key Tech & Partners:</span> Tigera, AWS Macie, Evident
                  </div>
                </div>
              </div>
              <div className="service-row">
                <div className="text-wrap">
                  <div className="title">Sophisticated Monitoring & Logging</div>
                  <p>
                    Monitor application metrics for security & performance (APM). Log and search everything.
                  </p>
                  <div className="key-tools">
                    <span>Key Tech & Partners:</span> New Relic, Prometheus, Datadog, Papertrail
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <img src="/cybersec.svg" />
            </div>
          </div>
        </section>
        <CallToActionBanner route={'insights'} linkText={'See Case Studies'} />
      </div>
    )
  }
}

export default CloudSecOpsContent
