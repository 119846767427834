import React from 'react'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import '../templates/base.scss'
import Layout from '../components/Layout/Layout'
import SEO from '../components/SEO'
import CloudSecOpsContent from '../components/CloudSecOps/CloudSecOpsContent/CloudSecOpsContent'
import CloudSecOpsHero from '../components/CloudSecOps/CloudSecOpsHero/CloudSecOpsHero'

const CloudSecOps = ({ data }) => {
  const postNode = {
    title: `CloudSecOps - ${config.siteTitle}`,
  }

  return (
    <Layout>
      <SEO postNode={postNode} pagePath="cloudsecops" customTitle />
      <Helmet>
        <title>{`CloudSecOps - ${config.siteTitle}`}</title>
      </Helmet>
      <CloudSecOpsHero />
      <CloudSecOpsContent />
    </Layout>
  )
}

export default CloudSecOps
